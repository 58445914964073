* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: 0;
}

html, body, #root {
    height: 100%;
    overflow: hidden;
}

iframe {
    width: 100%;
    height: 100%;
}
